import { Component, Vue } from 'vue-property-decorator'
import FormDocumentLegal from '@/components/LegalForm/Document/Document.vue'
import http from '@/services/http'
import LegalDocument from '@/Models/LegalDocument'
import Search from '@/components/Search/Search.vue'
import Crm from '@/Models/Crm'
import ModalDelete from '@/components/ModalDelete/ModalDelete.vue'
import * as Helpers from '@/helpers'
import menuModule from '@/store/menu'
import router from '@/router'
import Header from '@/components/Header/Header.vue'
import Animation from '@/components/Animation/index.vue'

@Component({
  components: {
    FormDocumentLegal,
    Search,
    ModalDelete,
    Header,
    Animation,
  },
})
export default class ViewDocumentLegal extends Vue {
  showList = true
  data: Array<LegalDocument> = []
  objLegalDocument: LegalDocument = new LegalDocument()
  action = 'add'
  member: Crm = new Crm()
  loading = false
  delete_modal_active = false
  baseUrl = ''
  header: Array<any> = [
    {
      field: 'date_read',
      label: 'Fecha',
    },
    {
      field: 'comment',
      label: 'Comentario',
    },
    {
      field: 'file',
      label: 'Doc',
    },
  ]
  mounted() {
    this.getLegalDocument()
    this.baseUrl = process.env.VUE_APP_API_URL
  }

  async getLegalDocument() {
    this.loading = true
    try {
      const id = menuModule.id_param
      if (id == null) throw router.push({ name: 'CCSS' }).catch(console.log)
      const res = await http.get(`/api/legal/arcr_legal_documents/${id}/`)
      if (!res.data) throw new Error(res.data)
      if (res.data.documents != 'No tiene documentos') {
        this.data = res.data.documents.map((obj: any) => ({
          ...obj,
          legal_id: id,
          date_read: obj.date_presented,
          file: obj.file != null ? obj.file.slice(1) : obj.file,
          date_presented: Helpers.dateParse(obj.date_presented),
          upload:
            obj.file != null
              ? new File(
                  ['foo'],
                  obj.file.substring(obj.file.lastIndexOf('/') + 1),
                )
              : null,
          document_status: obj.status,
        }))
      }
      this.member = res.data.member
    } catch (error) {
      console.error(error)
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
    this.loading = false
  }

  editObj(props: any) {
    this.objLegalDocument = props
    this.showList = false
    this.action = 'edit'
  }
  async deleteObj() {
    try {
      await http.delete(
        `/api/legal/arcr_legal_documents/${this.objLegalDocument.id}/`,
      )
      this.data = this.data.filter(
        (a: LegalDocument) => a.id !== this.objLegalDocument.id,
      )
      this.delete_modal_active = false
      this.getLegalDocument()
    } catch (error) {
      console.log(error)
      //toast.error("An error has occurred please try again.")
    }
  }
  deleteConfirm(props: any) {
    this.delete_modal_active = true
    this.objLegalDocument = props
  }
  closeModal() {
    this.objLegalDocument = new LegalDocument()
    this.delete_modal_active = false
  }
  openForm() {
    this.showList = false
  }
  async cleanForm() {
    ;(this.$refs.header as any).showList = true
    this.objLegalDocument = new LegalDocument()
    this.showList = true
    this.action = 'add'
    await this.getLegalDocument()
  }
  isData(data: any) {
    this.data = data
  }
}
