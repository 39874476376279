export default class Crm {
  id!: number
  member_number!: number
  client_name!: string
  client_email!: string
  client_email_alternative!: string
  client_type_identification!: string
  type_residency!: string
  client_identification!: string
  birth_date!: Date | null
  date_expire_identification!: Date | null
  passport!: string
  phone!: number
  house_phone!: number
  work_phone!: number
  province!: string
  canton!: string
  district!: string
  date_approbation!: Date | null
  date_start_membership!: Date | null
  date_expire_membership!: Date | null
  membership_cost!: number
  address!: string
  observation!: string
  migratory_status!: string
  fileUrl!: string
  upload!: File
  parent_client!: number
}
